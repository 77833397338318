import * as React from "react"
import { graphql } from "gatsby"

import { Container, Sidebar } from "../components"
import Seo from "../components/seo"
import { ContentWrapper } from "../elements"
import { HeaderTitle } from "../section"

const About = ({ data }) => (
  <Container>
    <Seo title="Mani's Blogについて" stylesheet={data.css.publicURL} />
    <section className="mb-16 mx-auto px-6 max-w-5xl md:mb-24">
      <HeaderTitle title="About Mani's Blog" subTitle="Mani's Blogについて" />
      <ContentWrapper>
        <div className="col-span-full lg:col-span-2">
          <div className="mb-12">
            <p className="mb-4 text-sm leading-6 md:text-base md:leading-7">
              Mani’s Blog
              はメンズ美容を追求し続けている男による、メンズ美容専門メディアです。
              <br />
              <br />
              運営者である私自身が美容との出会いで自分のコンプレックスに打ち勝ち、人生が変わった経験から、美容の魅力や可能性を広めたいという思いから誕生しました。
              <br />
              <br />
              また、欧米や韓国では「男も化粧するのが当たり前」となってきています。
              <br />
              スキンケアにこだわることや化粧をすることは恥ずかしいことではないことを広め、少しでも見ていただいている方がキレイになり自分に自信を持てるきっかけになれたら幸いです。
            </p>
          </div>
          <div className="mb-12">
            <div className="highlight w-fit-content mb-6 mx-auto md:mb-8">
              <h2 className="text-lg font-bold tracking-wide bg-transparent text-black m-0 p-0">
                Mani's Blogの特徴
              </h2>
            </div>
            <div className="mb-4 md:mb-6">
              <div className="flex items-center mb-4 md:mb-6">
                <img
                  className="w-4 h-auto m-0"
                  src={data.bullet.publicURL}
                  alt=""
                />
                <h4 className="ml-2 text-sm font-bold md:text-base bg-none">
                  常に最先端の情報を発信
                </h4>
              </div>
              <p className="text-sm leading-6 md:text-base md:leading-7">
                男性化粧品に関する日本語の優良記事は比較的少ないです。私自身が海外経験があるので欧米などの最先端メンズ美容事情を誰よりも早く分かりやすくお届けします。
              </p>
            </div>
            <div className="mb-4 md:mb-6">
              <div className="flex items-center mb-4 md:mb-6">
                <img
                  className="w-4 h-auto m-0"
                  src={data.bullet.publicURL}
                  alt=""
                />
                <h4 className="ml-2 text-sm font-bold md:text-base bg-none">
                  実際の自分の経験談のみの紹介
                </h4>
              </div>
              <p className="text-sm leading-6 md:text-base md:leading-7">
                私が体験してきた内容を忖度なしで紹介します。どこの組織にも属さないので私がいち消費者として感じた内容を書いていきます。
              </p>
            </div>
            <div className="mb-4 md:mb-6">
              <div className="flex items-center mb-4 md:mb-6">
                <img
                  className="w-4 h-auto m-0"
                  src={data.bullet.publicURL}
                  alt=""
                />
                <h4 className="ml-2 text-sm font-bold md:text-base bg-none">
                  豊富なコンテンツ
                </h4>
              </div>
              <p className="text-sm leading-6 md:text-base md:leading-7">
                美容は必ずしも見た目だけではありません。したがって、当ブログでは外見磨きだけでは終わらないトータルビューティーを目指します。
              </p>
            </div>
          </div>
          <div className="mb-12">
            <div className="highlight w-fit-content mb-6 mx-auto md:mb-8">
              <h2 className="text-lg font-bold tracking-wide bg-transparent text-black m-0 p-0">
                こんな人におすすめ
              </h2>
            </div>
            <ul className="">
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  スキンケアや化粧品に興味がある
                </h6>
              </li>
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  正しい美容に関する知識を知りたい
                </h6>
              </li>
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  自分の肌にあったスキンケア用品や化粧品を選べるようになりたい
                </h6>
              </li>
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  より価値の高い人になりたい
                </h6>
              </li>
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  健康な心と体を手に入れたい
                </h6>
              </li>
              <li className="flex items-center mb-2">
                <h6 className="ml-2 text-sm font-bold md:text-base">
                  色気のある大人を目指したい
                </h6>
              </li>
            </ul>
          </div>
        </div>
        <Sidebar />
      </ContentWrapper>
    </section>
  </Container>
)

export default About

export const query = graphql`
  {
    check: file(relativePath: { eq: "check.svg" }) {
      publicURL
    }
    bullet: file(relativePath: { eq: "bullet.svg" }) {
      publicURL
    }
    css: file(relativePath: { eq: "post.css" }) {
      publicURL
    }
  }
`
